import React, { Component } from 'react';

import './Front.css';

class FrontPage extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>Front page content</h1>
      </React.Fragment>
    );
  }
}

export default FrontPage;
